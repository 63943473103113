import { v4 as uuidv4 } from "uuid";
import bg1 from "../../assets/img/services/arch.png";
import bg2 from "../../assets/img/services/home.png";
import bg3 from "../../assets/img/services/interior.png";
import bg4 from "../../assets/img/services/paint.png";
import bg5 from "../../assets/img/services/Sketching.png";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "blueprint",
    title: "Architecture",
    desc: "From conceptualization to construction, we blend creativity with functionality to design architectural marvels that stand the test of time.",
  },

  {
    id: uuidv4(),
    bg: bg2,
    icon: "interior-design",
    title: "Interior Design",
    desc: "Transform your living or working space into a personalized oasis of comfort and style with our expert interior design solutions tailored to your tastes.",
  },

  {
    id: uuidv4(),
    bg: bg3,
    icon: "home",
    title: "Home Decoration",
    desc: "Elevate the ambiance of your home with our decorative expertise, ensuring every corner reflects your personality and enhances your lifestyle.",
  },

  {
    id: uuidv4(),
    bg: bg4,
    icon: "varnish",
    title: "Repair & Painting",
    desc: "Restore the beauty of your spaces with our meticulous repair and painting services, delivering flawless finishes and lasting impressions.",
  },

  {
    id: uuidv4(),
    bg: bg5,
    icon: "sketch-1",
    title: "Sketching",
    desc: "Capture your visions on paper with our skilled sketching techniques, providing the foundation for transforming ideas into tangible designs.",
  },
  //   {
  //     id: uuidv4(),
  //     bg: bg1,
  //     icon: "construction",
  //     title: "Construction Management",
  //     desc: "Let us take charge of your construction project, ensuring smooth coordination, timely completion, and adherence to the highest quality standards.",
  //   },
];

export default servicesOneData;

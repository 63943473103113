import React from "react";

import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/our-Services.png";
import PageBanner from "../Components/PageBanner";
import ServicesOne from "../Components/Services/ServicesOne";
import ServicesCta from "../Components/Services/ServicesCta";
import BestFeatures from "../Components/Features/BestFeatures";
import Pricing from "../Components/Price/Pricing";
import FaqOne from "../Components/Faq/FaqOne";
import FooterOne from "../Components/Footer/FooterOne";

const ServicePage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner
        title="Our Services"
        bannerBg={bannerBg}
        currentPage="Service"
      />
      <ServicesOne />
      <ServicesCta />
      <BestFeatures />
      {/* <Pricing/> */}
      <FaqOne />
      <FooterOne />
    </>
  );
};

export default ServicePage;

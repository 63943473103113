import { v4 as uuidv4 } from "uuid";
import portfolio1 from "../../assets/img/project/residentialHome.jpg";
import portfolio2 from "../../assets/img/project/complex.jpg";
import portfolio3 from "../../assets/img/project/Public-Space.png";
import portfolio4 from "../../assets/img/project/Hospitality.png";

const portfolioTwoData = [
  {
    id: uuidv4(),
    img: portfolio1,
    title: "Modern Residential Complex",
    category: "Residential",
    location: "Hyderabad, Telangana",
    description:
      "A luxurious residential complex featuring modern architecture and amenities.",
    client: "ABC Developers",
  },
  {
    id: uuidv4(),
    img: portfolio2,
    title: "Commercial Office Tower",
    category: "Commercial",
    location: "Nellore, Andhra Pradesh",
    description:
      "A state-of-the-art office tower designed for maximum productivity and comfort.",
    client: "XYZ Corporation",
  },
  {
    id: uuidv4(),
    img: portfolio3,
    title: "Urban Park Redevelopment",
    category: "Public Spaces",
    location: "Warangal, Telangana",
    description:
      "A revitalized urban park offering green spaces and recreational facilities for the community.",
    client: "City Municipality",
  },
  {
    id: uuidv4(),
    img: portfolio4,
    title: "Hospitality Resort & Spa",
    category: "Hospitality",
    location: "Tirupati, Andhra Pradesh",
    description:
      "A luxurious resort and spa providing a tranquil escape with world-class amenities.",
    client: "Sunrise Hotels & Resorts",
  },
];

export default portfolioTwoData;

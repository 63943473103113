import { v4 as uuidv4 } from "uuid";
import portfolio1 from "../../assets/img/project/homep.jpg";
import portfolio2 from "../../assets/img/project/Downtown.png";
import portfolio3 from "../../assets/img/project/Beach-resort.png";
import portfolio4 from "../../assets/img/project/3d-School.png";

const portfolioOneData = [
  {
    id: uuidv4(),
    img: portfolio1,
    category: "Residential",
    title: "Modern Family Home",
    date: "May 15, 2023",
  },

  {
    id: uuidv4(),
    img: portfolio2,
    category: "Commercial",
    title: "Downtown Office Complex",
    date: "July 8, 2022",
  },

  {
    id: uuidv4(),
    img: portfolio3,
    category: "Hospitality",
    title: "Luxury Beach Resort",
    date: "August 20, 2023",
  },

  {
    id: uuidv4(),
    img: portfolio4,
    category: "Educational",
    title: "Innovative School Design",
    date: "April 2, 2023",
  },
];

export default portfolioOneData;

import React from "react";

import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import Details from "../Components/Services/Details";
import FooterOne from "../Components/Footer/FooterOne";

const ServiceDetails = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner
        title="INTERIOR DESIGN"
        bannerBg={bannerBg}
        currentPage="SERVICE DETAILS"
      />
      <Details />
      <FooterOne />
    </>
  );
};

export default ServiceDetails;

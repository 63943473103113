import { Link } from "react-router-dom";
import servicesOneData from "./servicesOneData";

const ServicesOne = () => {
  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row m-auto">
          <div className="col-md-8 mb-3">
            <div className="block-contents">
              <div className="section-title">
                <span>02. Explore Our Services</span>
                <h2>Elevate Your Projects with 3D Plans</h2>
              </div>
              <p>
                Discover how our innovative solutions can enhance your
                construction projects and bring your vision to life.
              </p>
            </div>
          </div>
        </div>
        <div className="row mtm-30">
          {servicesOneData.map((data) => (
            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
              <div className="single-service-box style-1">
                <div
                  className="service-bg bg-cover"
                  style={{ backgroundImage: `url(${data.bg})` }}
                ></div>
                <div className="icon">
                  <i className={`flaticon-${data.icon}`}></i>
                </div>
                <div className="contents">
                  <h4>
                    <Link to="/servicesDetails">{data.title}</Link>
                  </h4>
                  <p>{data.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;

import React from "react";
import bannerBg from "../../assets/img/Team-Work.png";

const BestFeatures = () => {
  return (
    <section className="best-featured-wrapper section-padding pb-5">
      <div className="container">
        <div className="row mtm-40 align-items-center">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-flow-chart" />
              </div>
              <div className="content-text">
                <h5>Innovative Technology</h5>
                <p>
                  Utilizing cutting-edge technology to ensure precision and
                  efficiency in every project.
                </p>
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-writing" />
              </div>
              <div className="content-text">
                <h5>Strategic Planning</h5>
                <p>
                  Developing comprehensive plans tailored to meet your specific
                  requirements and objectives.
                </p>
              </div>
            </div>
            <div className="single-best-featured right">
              <div className="icon">
                <i className="flaticon-interior-design" />
              </div>
              <div className="content-text">
                <h5>Optimized Solutions</h5>
                <p>
                  Delivering efficient and sustainable solutions to address your
                  project needs effectively.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-md-0 mt-5 mb-5 mt-md-0 mb-md-0 col-md-6 col-12">
            <div className="center-img text-center mbm-50">
              <img src={bannerBg} alt="" />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-secure-shield" />
              </div>
              <div className="content-text">
                <h5>Trusted Partnerships</h5>
                <p>
                  Building long-term relationships based on trust, reliability,
                  and exceptional service.
                </p>
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-team" />
              </div>
              <div className="content-text">
                <h5>Collaborative Team</h5>
                <p>
                  Working together seamlessly to bring creativity, expertise,
                  and innovation to every project.
                </p>
              </div>
            </div>
            <div className="single-best-featured">
              <div className="icon">
                <i className="flaticon-profits" />
              </div>
              <div className="content-text">
                <h5>Cost-Effective Solutions</h5>
                <p>
                  Offering cost-effective solutions without compromising quality
                  or excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestFeatures;

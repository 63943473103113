import React from "react";

import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/About-Us.png";
import PageBanner from "../Components/PageBanner";
import PortfolioOne from "../Components/Portfolio/PortfolioOne";
import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import FooterOne from "../Components/Footer/FooterOne";

const ProjectPage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner title="OUR WORKS" bannerBg={bannerBg} currentPage="Our Works" />
      <PortfolioOne />
      <PortfolioTwo />
      <BrandCarouselThree />
      <FooterOne />
    </>
  );
};

export default ProjectPage;

import React from "react";

import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/faq-banner.png";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import FaqOne from "../Components/Faq/FaqOne";
import CtaOne from "../Components/Cta/CtaOne";
import FooterOne from "../Components/Footer/FooterOne";

const FaqPage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner title="FAQ" bannerBg={bannerBg} currentPage="faq" />
      <FaqOne />
      <CtaOne />
      <BrandCarouselThree />
      <FooterOne />
    </>
  );
};

export default FaqPage;

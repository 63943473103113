import axios from "axios";

const Instence = axios.create({
  baseURL: "https://3dplan20240808145005.azurewebsites.net/api",
  headers: { Accept: "application/json" },
});

export default Instence;

// https://way2landmark.drmarins.cloud/api/User

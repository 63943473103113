import {v4 as uuidv4} from 'uuid';
import brand1 from '../../assets/img/brand-logo/b1.png';
import brand2 from '../../assets/img/brand-logo/b2.png';
import brand3 from '../../assets/img/brand-logo/b3.png';
import brand4 from '../../assets/img/brand-logo/b4.png';
import brand5 from '../../assets/img/brand-logo/b5.png';


const brandCarouselOneData = [
    {
        id: uuidv4(),
        brand: brand1,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: brand2,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: brand3,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: brand4,
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: brand5,
        name: 'Google'
    },
    
]

export default brandCarouselOneData;
import React from "react";
import bannerBg from "../../assets/img/Services.png";

const ServicesCta = () => {
  return (
    <section
      className="pricing-cta-wrapper text-white bg-cover bg-center section-padding"
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 text-center col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 ps-xl-0 pe-xl-0">
            <div className="pricing-contents">
              <h1>We Bring Your Dreams to Reality</h1>
              <p className="mt-3">
                Transform your space with our comprehensive renovating services.
                Whether it's a residential project or a commercial venture, we
                have the expertise and dedication to turn your vision into a
                stunning reality.
              </p>
              <div className="btn-wrapper d-flex align-items-end justify-content-center">
                <a href="contact">Contact Us</a>
                <div className="btn-cta">
                  <div className="icon">
                    <i className="flaticon-24-hours" />
                  </div>
                  <div className="content">
                    <span className="text-star">Call for support</span>
                    <h3>+91 9876543210</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesCta;

import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { validationSchema } from "../../utils/YupSchemas";
import Instence from "../../utils/Instence";
import Swal from "sweetalert2";

const ContactPageContents = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    stages: "",
    audioFile:"",
  });
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log("File MIME type:", file.type);
    setFile(file);
  };

  const handleAudioRecord = async () => {
    if (recording) {
      mediaRecorder.stop();
      setRecording(false);
    } else {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      const chunks = [];

      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(blob);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false }); // Validate form data
      const formDataToSend = new FormData();
      formDataToSend.append("file", file);
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("subject", formData.subject);
      formDataToSend.append("message", formData.message);
      formDataToSend.append("PlanType", formData.stages);
      if (audioBlob) {
        formDataToSend.append("AudioFile", audioBlob, "audio.wav");
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          // Add any other custom headers for the file here if needed
        },
      };

      const response = await Instence.post(
        "QueryForm/postQueryForm",
        formDataToSend,
        config
      );
      if (response.status === 200) {
        console.log("Form submitted successfully!", response);
        // Reset form fields if needed
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
          stages: "",
        });
        setFile(null); // Reset file input
        setAudioBlob(null); // Reset audio blob if needed
        setErrors({});
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your operation was successful.",
          showConfirmButton: false,
          timer: 2000, // Automatically close after 2 seconds
        });
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
        setTimeout(clearErrors, 2000);
      } else {
        console.error("Error submitting form:", error);
        // Handle other types of errors
      }
    }
  };
  const clearErrors = () => {
    setErrors({});
  };

  return (
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-12 col-md-12 mb-4">
            <div className="row">
              {/* Card 1 */}
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">3D Floor Plans</h5>
                    <p className="card-text">
                      These plans provide a detailed visual representation of a
                      building's layout, including rooms, furniture, and spatial
                      relationships.
                    </p>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">3D Elevations</h5>
                    <p className="card-text">
                      3D architectural elevations are detailed representations
                      of a building's exterior facades or sides in
                      three-dimensional space.
                    </p>
                  </div>
                </div>
              </div>
              {/* Card 3 */}
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Contruction</h5>
                    <p className="card-text">
                      Construction 3D plans are visual representations of a
                      construction project that provide detailed insights into
                      the building process.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Others</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form">
              <h2>Get in Touch</h2>
              <form className="row" id="contact-form" onSubmit={handleSubmit}>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name"
                    />
                    {errors.name && (
                      <div className="error-message">*{errors.name}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                    />
                    {errors.email && (
                      <div className="error-message">*{errors.email}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Number"
                    />
                    {errors.phone && (
                      <div className="error-message">*{errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="Subject"
                    />
                    {errors.subject && (
                      <div className="error-message">*{errors.subject}</div>
                    )}
                  </div>
                </div>
                <legend>Plans Types </legend>
                <fieldset className="radio-group">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="radio-butons">
                        <input
                          type="radio"
                          name="stages"
                          value="3D Floor Plans/3DS"
                          checked={formData.stages === "3D Floor Plans/3DS"}
                          onChange={handleChange}
                        />
                        <label htmlFor="">3D Floor Plans/3DS</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="radio-butons">
                        <input
                          type="radio"
                          name="stages"
                          value="3D Elevations/Views/Edits"
                          checked={
                            formData.stages === "3D Elevations/Views/Edits"
                          }
                          onChange={handleChange}
                        />
                        <label htmlFor="">3D Elevations/Views/Edits</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="radio-butons">
                        <input
                          className="RadioButton"
                          type="radio"
                          name="stages"
                          value="Contructions/Structures/Working"
                          checked={
                            formData.stages ===
                            "Contructions/Structures/Working"
                          }
                          onChange={handleChange}
                        />
                        <label htmlFor="">
                          Contructions/Structures/Working
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="radio-butons">
                        <input
                          type="radio"
                          name="stages"
                          value="Others/Site Inspection"
                          checked={formData.stages === "Others/Site Inspection"}
                          onChange={handleChange}
                        />
                        <label htmlFor="">Others/Site Inspection</label>
                      </div>
                    </div>
                  </div>
                </fieldset>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Message"
                    />
                    {errors.message && (
                      <div className="error-message">*{errors.message}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <input
                      name="file"
                      onChange={handleFileChange}
                      type="file"
                    />
                    {errors.file && (
                      <div className="error-message">*{errors.file}</div>
                    )}
                  </div>
                </div>
                {/* Setup voice recording here */}

                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <div className="audioRec">
                      <button
                        onClick={handleAudioRecord}
                        className="recording-btn"
                        type="button"
                      >
                        {recording ? "Stop Recording" : "Start Recording"}
                      </button>
                      {audioBlob && (
                        <audio controls src={URL.createObjectURL(audioBlob)} />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-12">
                  <input
                    className="submit-btn"
                    type="submit"
                    value="Submit Now"
                  />
                </div>
              </form>

              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h2>CONTACT INFO</h2>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-email" />
                  </div>
                  <div className="text">
                    <span>Email Us</span>
                    <h5>3dplans@example.com</h5>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Call Us</span>
                    <h5>+673 853 605 985</h5>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>Location</span>
                    <h5>Chagallu, Andhra Pradesh</h5>
                  </div>
                </div>
              </div>
              {/* <div className="live-chat">
                <h2>Live Chat</h2>
                <p>You can contact us on chat and explain your problems</p>
                <Link to="/about">
                  <i className="flaticon-chatting" /> Live Chat to Executive
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default ContactPageContents;

import { isValidElement } from "react";
import { v4 as uuidv4 } from "uuid";

const faqOneData = [
  {
    id: uuidv4(),
    ques: "How do I begin the process of hiring an architect for my project?",
    ans: "The first step in hiring an architect is to clearly define your project goals, budget, and timeline. Once you have a clear vision, you can start researching architects who specialize in the type of project you're undertaking. Look for architects with experience, a strong portfolio, and positive client testimonials. Schedule meetings or interviews with potential architects to discuss your project in detail and determine if they're the right fit for your needs.",
    index: "faq1",
  },

  {
    id: uuidv4(),
    ques: "What services do architects typically offer?",
    ans: "Architects offer a range of services depending on the scope of the project. These services may include initial design consultations, site analysis, schematic design, design development, construction documentation, permitting assistance, contractor selection, and construction administration. Some architects also provide additional services such as interior design, landscape design, sustainable design, and project management.",
    index: "faq2",
  },

  {
    id: uuidv4(),
    ques: "How do architects determine their fees?",
    ans: "Architects typically determine their fees based on several factors, including the scope and complexity of the project, the level of experience and expertise required, the architect's reputation and track record, and the geographic location of the project. Fees may be structured as a percentage of the total construction cost, a fixed fee, or an hourly rate. It's important to discuss fee structures and payment schedules with your architect before starting work on your project.",
    index: "faq3",
  },

  {
    id: uuidv4(),
    ques: "What should I look for when hiring an architect?",
    ans: "When hiring an architect, it's important to look for someone who understands your vision, communicates effectively, and has the necessary expertise to bring your project to life. Consider factors such as the architect's portfolio, design style, professional qualifications, and experience working on similar projects. Additionally, make sure to ask for references and speak with past clients to get a sense of the architect's working style and ability to meet deadlines and budgets.",
    index: "faq4",
  },

  {
    id: uuidv4(),
    ques: "Can I work with an architect remotely?",
    ans: "Yes, many architects offer remote services and can work with clients from anywhere in the world. With advancements in technology such as video conferencing, email, and project management software, it's easier than ever to collaborate with an architect remotely. However, it's important to establish clear communication channels and expectations upfront to ensure a successful working relationship.",
    index: "faq5",
  },
];

export default faqOneData;

import {v4 as uuidv4} from 'uuid';
import img1 from '../../assets/img/blog/pp2.jpg';

const feedsData = [
    {
        id: uuidv4(),
        img: img1,
        title: 'LOREM IPSUM DOLOR SIT CING ELIT, SED DO.',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'LOREM IPSUM DOLOR SIT CING ELIT, SED DO.',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'LOREM IPSUM DOLOR SIT CING ELIT, SED DO.',
        meta: '24th March 2019',
        link: '#'
    },

    {
        id: uuidv4(),
        img: img1,
        title: 'LOREM IPSUM DOLOR SIT CING ELIT, SED DO.',
        meta: '24th March 2019',
        link: '#'
    },
    
]

export default feedsData
import React from "react";
import icon1 from "../assets/img/icons/t1.png";
import icon2 from "../assets/img/icons/t2.png";

const Timeline = () => {
  return (
    <section className="company-timeline-wrapper section-padding">
      <div className="container">
        <div className="row mtm-40">
          <div className="col-lg-4  col-md-6 offset-lg-1 pe-lg-0">
            <div className="timeline-content text-lg-end">
              <div className="single-timeline-box">
                <span>1990 - 1995</span>
                <h6>Founding of Our Company</h6>
                <p>
                  Our journey began with a vision to revolutionize the
                  construction industry. We laid the foundation of our company
                  with a commitment to excellence and innovation.
                </p>
              </div>
              <div className="single-timeline-box">
                <span>1990 - 1995</span>
                <h6>Expansion and Growth</h6>
                <p>
                  Driven by our passion and dedication, we expanded our
                  operations and embarked on a journey of growth. We diversified
                  our services and expanded our clientele.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 d-none mt-40 d-lg-block">
            <div className="time-line-icon">
              <div className="single-icon">
                <img src={icon1} alt="" />
              </div>
              <div className="single-icon">
                <img src={icon2} alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 ps-lg-0">
            <div className="timeline-content">
              <div className="single-timeline-box">
                <span>1988 - 2006</span>
                <h6>Innovation and Excellence</h6>
                <p>
                  We embraced innovation and upheld the highest standards of
                  excellence in every project we undertook. Our commitment to
                  quality and innovation set us apart.
                </p>
              </div>
              <div className="single-timeline-box">
                <span>2010 - 2020</span>
                <h6>Leading the Industry</h6>
                <p>
                  Today, we stand as a leader in the construction industry,
                  known for our innovative designs, superior craftsmanship, and
                  unwavering commitment to client satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-lg-5 mt-3">
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-trust"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Trusted</span> by Clients Worldwide
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-cashier-machine"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Secure</span> Registered by Government
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 col-lg-4">
            <div className="single-icon-border-box">
              <div className="icon">
                <i className="flaticon-goal"></i>
              </div>
              <div className="content">
                <h6>
                  <span>Mission</span> for Excellence
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Timeline;

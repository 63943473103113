import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/home1/slide.jpg";
import hs1 from "../../assets/img/home1/hs1.png";
import hs2 from "../../assets/img/home1/hs2.jpg";
import hs3 from "../../assets/img/home1/hs3.jpg";
import hs4 from "../../assets/img/home1/hs4.png";
import hs5 from "../../assets/img/home1/hs5.jpg";

import "swiper/css";
import "swiper/css/navigation";
// import { Navigation } from "swiper";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Link } from "react-router-dom";

const HeroOne = () => {
  SwiperCore.use([Autoplay, Navigation]);

  return (
    <section className="hero-wrapper hero-1">
      <Swiper
        navigation={true}
        modules={[Navigation]}
        autoplay={{
          delay: 3000, // Delay between transitions (in milliseconds)
          disableOnInteraction: false, // Enable autoplay when user interacts with the slider (default is true)
        }}
        loop
      >
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${slide1})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    Post Query
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${hs1})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated text-muted"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${hs2})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${hs3})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${hs4})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide
          className=" bg-cover"
          style={{ backgroundImage: `url(${hs5})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                <div className="hero-contents pe-lg-3">
                  <h1
                    className="fs-lg wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                  >
                    we build Your Dream
                  </h1>
                  <p
                    className="pe-lg-5 wow fadeInLeft animated"
                    data-wow-duration="1.3s"
                    data-wow-delay=".4s"
                  >
                    Transforming Dreams into Reality with 3D Plans
                  </p>
                  <Link
                    to="/contact"
                    className="theme-btn me-sm-4 wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".8s"
                  >
                    contact us
                  </Link>
                  <Link
                    to="/about"
                    className="plus-text-btn wow fadeInLeft"
                    data-wow-duration="1.2s"
                    data-wow-delay=".6s"
                  >
                    <div className="icon">
                      <i className="fas fa-plus"></i>
                    </div>
                    <div className="link-text">
                      <span>Explore</span> More about Us
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default HeroOne;

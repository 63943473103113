import React from "react";

const AboutBanner = () => {
  return (
    <section className="about-banner-wrapper section-padding theme-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 text-center">
            <div className="block-contents">
              <div className="section-title">
                <span>Insight of The Company</span>
                <h2 className="text-white">Bringing Your Dreams to Life</h2>
                <p className="text-white">
                  With over 30 years of experience, we've been transforming
                  visions into reality. From innovative architectural designs to
                  sustainable construction practices, we're dedicated to
                  exceeding expectations and creating spaces that inspire.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="icon-arrow-down">
        <div className="rotate-icon">
          <i className="flaticon-navigation"></i>
        </div>
      </div>
    </section>
  );
};

export default AboutBanner;

import React from "react";

import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/About-Us.png";
import PageBanner from "../Components/PageBanner";
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import FooterOne from "../Components/Footer/FooterOne";

const ContactPage = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner title="Query Form" bannerBg={bannerBg} currentPage="Query" />
      <ContactPageContents />
      <BrandCarouselThree />
      <FooterOne />
    </>
  );
};

export default ContactPage;

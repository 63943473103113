import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/client/1.jpg";

const TestimonialTwoData = [
  {
    id: uuidv4(),
    img: img1,
    name: "Sagar Chandra",
    designation: "Architect, Johnson & Associates",
    speech:
      "Working with 3D Plans has been an incredible experience. Their attention to detail and commitment to quality have made our projects stand out. I highly recommend their services to anyone in need of innovative architectural solutions.",
  },
  {
    id: uuidv4(),
    img: img1,
    name: "Rajesh Dev",
    designation: "CEO, Smith Constructions",
    speech:
      "Choosing 3D Plans for our construction projects was the best decision we made. Their expertise and professionalism ensured smooth project execution and timely delivery. I look forward to working with them again in the future.",
  },
];

export default TestimonialTwoData;
